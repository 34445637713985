import axios from 'axios'
import {
    Message,
    Loading
} from 'element-ui';
import router from './router'
axios.defaults.withCredentials = true; //设置cross跨域 并设置访问权限 允许跨域携带cookie信息
console.log(process.env.NODE_ENV);
var domain = process.env.NODE_ENV === 'production' ? '' : 'https://xinzb.yunyiit.com'




//创建axios实例
var service = axios.create({
    baseURL: domain,
    timeout: 5000,
    headers: {
        'content-type': 'application/json',
        // "token": '14a1347f412b319b0fef270489f'
    }
})
export default {
    domain: domain,
    //post请求，其他类型请求复制粘贴，修改method
    post(url, param) {
        let loadingInstance = Loading.service({
            lock: true,
            text: 'Loading',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)',
            customClass: 'myLoading',
        });
        return new Promise((cback, reject) => {
            service({
                method: 'post',
                url,
                params: {
                    param: JSON.stringify(param)
                },
            }).then(res => {
                // console.log(res)
                loadingInstance.close();
                //axios返回的是一个promise对象
                var res_code = res.data.code.toString();
                if (res_code == 200) {
                    cback(res.data.data); //cback在promise执行器内部
                } else if (res_code == 201) {
                    // Message({
                    //     showClose: true,
                    //     message: res.data.msg,
                    //     type: 'error'
                    // });
                    router.push('/');
                } else {
                    Message({
                        showClose: true,
                        message: res.data.msg,
                        type: 'error'
                    });
                }
            }).catch(err => {
                loadingInstance.close();
                if (!err.response) {
                    console.log('请求错误')
                    //Message是element库的组件，可以去掉
                    Message({
                        showClose: true,
                        message: '请求错误',
                        type: 'error'
                    });
                } else {
                    reject(err.response);
                    console.log(err.response, '异常')
                }
            })

        })
    },
}
