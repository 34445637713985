import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);
import './base.css'
import './main.less'
import md5 from 'js-md5';
// import axios from 'axios';
import router from './router'

import http from './http.js' //axios实例化后引入取名http
Vue.prototype.http = http //放入全局
Vue.prototype.domain = http.domain; //放入全局
window.domain = http.domain;
Vue.prototype.$md5 = md5

Vue.prototype.bus = new Vue();
Vue.config.productionTip = false;

window.app = new Vue({
    router,
    render: h => h(App),
    data: function() {
        return {
            userInfo: '',
        };
    }
}).$mount('#app');
