import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
export default new Router({
    routes: [{
        path: '/',
        name: 'login',
        component: () => import('./views/login.vue')
    },{
        path: '/index',
        name: '首页',
        component: () => import('./views/index.vue'),
        children: [{
            path: '/admin',
            name: '管理员',
            component: () => import('./views/admin.vue'),
        },{
            path: '/video',
            name: '视频',
            component: () => import('./views/video.vue'),
        },{
            path: '/student',
            name: '学生',
            component: () => import('./views/student.vue'),
        },{
            path: '/code',
            name: '二维码',
            component: () => import('./views/code.vue'),
        },{
            path: '/overview',
            name: '统计概况',
            component: () => import('./views/overview.vue'),
        }]
    }]
})
